
#admin-container{
    max-width: 100%;
}

#header{
    color: white;
}

.admin-search-btn{
    height: 54px;
    text-transform: none !important;
    margin-left: 3px !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    align-self: center;
    white-space: nowrap;
}

.admin-search-btn:focus{
    outline-color: white !important;
}

#date > div > label{
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    transform: translate(14px, -6px) scale(0.75);
}

#admin-content{
    background: white;
    border-radius: 5px;
}

#date-picker > div > label{
    left: -7px;
}
