.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

#logging-trouble{
    color: white;
    margin-bottom: 8px;
}

#logging-trouble >a{
    cursor: pointer;
    text-decoration: underline;
    color: white;
}
