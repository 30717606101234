
.footer-extra{
    min-height: 99px;
    background: #1e1e1e;
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-container{
    color: white;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}

.footer-des-style{
    font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.footer-des-style > p {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-title-style{
    font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
