
.dialogContent-div-style{
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0px auto;
}

.dialogContent-p-style{
    max-width: 500px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 0.9rem !important;
}

.dialogAction-style{
    justify-content: center !important;
}

.dialogAction-btn-style{
    background-color: lightgray !important;
    text-transform: none !important;
}

.dialog-text-field-width{
    width: 300px !important;
}

.dialog-container-height{
    max-height: 100vh !important;
    min-height: 100vh !important;
}

.dialog-container-height > div > div{
    margin: 5px !important;
}

.winner-submit-header{
    text-align: center !important;
}

.winner-errors-style{
    margin-top: 0px !important;
}

.label {
    display: block;
    margin: 10px 0px;
    font-weight: 500;
}

.chkLabel {
    display: block;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    letter-spacing: 0.0075em;
}

.chkLabel > input {
    vertical-align: text-bottom;
}

.dialog-text-field-width {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}