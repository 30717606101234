
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root{
    background-color: #041e42;
    display: flex !important;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
}

.container-wrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.participants-container{
    padding: 10px;
}

.participants-card-style{
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding-top: 0px !important;
}

.participants-outerHeader-style{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.participants-innerHeader-style{
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    min-width: 80%;
}

.participant-heading{
    font-size: 15px;
    margin: 0px;
}

.admin-winners-heading{
    font-size: 18px;
    margin: 0px;
}

.heading{
    font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif;
    color: white;
    text-align: center;
    
}

.participants-needHelp-style{
    display: flex;
    padding: 10px 0px 0px 0px;
    align-items: center;
    margin-bottom: 5px;
}

.table-heading-style{
    padding-left: 16px !important;
}

.participants-needHelp-popover_class{
    max-width: 380px !important;
    left: 15px !important;
}

.participants-btn-style{
    text-transform: none !important;
    margin-left: 3px !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    align-self: center;
    white-space: nowrap;
}

.csv-link-style{
    display: block;
    padding: 10px 13px;
    text-transform: none !important;
    background-color: gainsboro !important;
    min-width: 64px;
    font-size: 0.875rem;
    box-sizing: border-box;
    min-height: 36px;
    max-height: 36px;
    line-height: 1.3125;
    border-radius: 4px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    color: royalblue !important;
    text-decoration: none !important;
    align-self: center;
    margin-left: 3px;
}

.logout-btn-style{
    text-transform: none !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    min-height: 36px;
    max-height: 36px;
    padding: 0px!important;
}

.import-btn-style{
    text-transform: none !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    min-height: 36px;
    max-height: 36px;
    margin: 0px 2px 0px 0px !important;
}


.logout-div-btn{
    display: flex;
    align-self: center;
}

.heading-div-style{
    width: 300px;
    min-width: 300px;
    flex: 1;
}

.export-button-style{
    margin-right: 7px !important;
    padding: 0px 25px 0px 29px !important;
}

.participants-btnDiv-style{
    display: flex;
    justify-content: flex-end;
    padding: 16px 5px !important;
}

.participants-form-style{
    display: flex;
    justify-content: flex-end;
    padding: 16px 5px !important;
}

.table-btn-style{
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    text-transform: none !important;
}

.tableHeaders{
    font-size: 14px !important;
    font-weight: bold !important;
    min-width: fit-content;
    white-space:nowrap;
}

.table-cell{
    padding: 3px !important;
    white-space: nowrap;
}

.table-pagination > div > div > div:first-child {
    margin-right: 8px !important;
}

.table-pagination > div > div[class*="MuiTablePagination-actions"] {
    margin-left: 5px !important;
}

.table-pagination > div > div[class*="MuiTablePagination-actions"] > button {
    padding: 8px !important;
}

.program-name-style{
    text-align: center;
}

.view-edit-remove-style{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.view-selection-style{
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.participants-inner-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 2px;
}

.program-name-heading{
    font-size: 20px;
}

tr::has(td[colspan="6"]) {
    display: none !important;
}

.btnAsLink {
    border: none;
    background: none;
}

.btnAsLink:hover {
    cursor: pointer;
}

.btnAsLink:disabled {
    color: rgba(1,1,1,0.5);
}

.btnAsLink:disabled:hover {
    color: rgba(0, 0, 0, 0.5);
    cursor: auto !important;
}

.phoneButton {
    border: 0;
    padding: 0;
    cursor: pointer;
    font-family: inherit; 
    font-size: 100%; 
    line-height: 1.15; 
    margin: 0; 
    background: inherit;
    text-decoration: underline;
    color: blue;
}
