.card-content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 500px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}

.NeedHelpStyle{
    padding-top: 35px;
    padding-bottom: 35px;
    justify-content: center;
}

.popover_class{
    max-width: 1060px !important;
    left: 55px !important;
    margin-right: 20px;
}

.label-style{
    font-weight: bold;
    color: #adad85;
    font-size: 12px;
}

.icon-button-style{
    padding: 0px !important;
}

.text-field-width{
    width: 300px !important;
}

.login-heading{
    flex: 1;
    margin: 0px;
}

.error-style{
    margin-top: 0px !important;
}

.server-error-style{
    margin-top: 0px !important;
    text-align: center !important;
}
