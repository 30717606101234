body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header{
    max-width: 500px;
    color: white;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

.card-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    max-width: 500px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}

.NeedHelpStyle{
    padding-top: 35px;
    padding-bottom: 35px;
    -webkit-justify-content: center;
            justify-content: center;
}

.popover_class{
    max-width: 1060px !important;
    left: 55px !important;
    margin-right: 20px;
}

.label-style{
    font-weight: bold;
    color: #adad85;
    font-size: 12px;
}

.icon-button-style{
    padding: 0px !important;
}

.text-field-width{
    width: 300px !important;
}

.login-heading{
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0px;
}

.error-style{
    margin-top: 0px !important;
}

.server-error-style{
    margin-top: 0px !important;
    text-align: center !important;
}


.footer-extra{
    min-height: 99px;
    background: #1e1e1e;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.footer-container{
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: wrap;
            flex-flow: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.footer-des-style{
    font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif;
    font-size: 11px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.footer-des-style > p {
    min-height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.footer-title-style{
    font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

#logging-trouble{
    color: white;
    margin-bottom: 8px;
}

#logging-trouble >a{
    cursor: pointer;
    text-decoration: underline;
    color: white;
}


.dialogContent-div-style{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px auto;
}

.dialogContent-p-style{
    max-width: 500px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 0.9rem !important;
}

.dialogAction-style{
    -webkit-justify-content: center !important;
            justify-content: center !important;
}

.dialogAction-btn-style{
    background-color: lightgray !important;
    text-transform: none !important;
}

.dialog-text-field-width{
    width: 300px !important;
}

.dialog-container-height{
    max-height: 100vh !important;
    min-height: 100vh !important;
}

.dialog-container-height > div > div{
    margin: 5px !important;
}

.winner-submit-header{
    text-align: center !important;
}

.winner-errors-style{
    margin-top: 0px !important;
}

.label {
    display: block;
    margin: 10px 0px;
    font-weight: 500;
}

.chkLabel {
    display: block;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    letter-spacing: 0.0075em;
}

.chkLabel > input {
    vertical-align: text-bottom;
}

.dialog-text-field-width {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
}

#root{
    background-color: #041e42;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
}

.container-wrapper{
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.participants-container{
    padding: 10px;
}

.participants-card-style{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: space-between;
            align-content: space-between;
    padding-top: 0px !important;
}

.participants-outerHeader-style{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.participants-innerHeader-style{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    min-width: 80%;
}

.participant-heading{
    font-size: 15px;
    margin: 0px;
}

.admin-winners-heading{
    font-size: 18px;
    margin: 0px;
}

.heading{
    font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif;
    color: white;
    text-align: center;
    
}

.participants-needHelp-style{
    display: -webkit-flex;
    display: flex;
    padding: 10px 0px 0px 0px;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 5px;
}

.table-heading-style{
    padding-left: 16px !important;
}

.participants-needHelp-popover_class{
    max-width: 380px !important;
    left: 15px !important;
}

.participants-btn-style{
    text-transform: none !important;
    margin-left: 3px !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    -webkit-align-self: center;
            align-self: center;
    white-space: nowrap;
}

.csv-link-style{
    display: block;
    padding: 10px 13px;
    text-transform: none !important;
    background-color: gainsboro !important;
    min-width: 64px;
    font-size: 0.875rem;
    box-sizing: border-box;
    min-height: 36px;
    max-height: 36px;
    line-height: 1.3125;
    border-radius: 4px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    color: royalblue !important;
    text-decoration: none !important;
    -webkit-align-self: center;
            align-self: center;
    margin-left: 3px;
}

.logout-btn-style{
    text-transform: none !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    min-height: 36px;
    max-height: 36px;
    padding: 0px!important;
}

.import-btn-style{
    text-transform: none !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    min-height: 36px;
    max-height: 36px;
    margin: 0px 2px 0px 0px !important;
}


.logout-div-btn{
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
}

.heading-div-style{
    width: 300px;
    min-width: 300px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.export-button-style{
    margin-right: 7px !important;
    padding: 0px 25px 0px 29px !important;
}

.participants-btnDiv-style{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 16px 5px !important;
}

.participants-form-style{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 16px 5px !important;
}

.table-btn-style{
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    text-transform: none !important;
}

.tableHeaders{
    font-size: 14px !important;
    font-weight: bold !important;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    white-space:nowrap;
}

.table-cell{
    padding: 3px !important;
    white-space: nowrap;
}

.table-pagination > div > div > div:first-child {
    margin-right: 8px !important;
}

.table-pagination > div > div[class*="MuiTablePagination-actions"] {
    margin-left: 5px !important;
}

.table-pagination > div > div[class*="MuiTablePagination-actions"] > button {
    padding: 8px !important;
}

.program-name-style{
    text-align: center;
}

.view-edit-remove-style{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.view-selection-style{
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.participants-inner-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-bottom: 2px;
}

.program-name-heading{
    font-size: 20px;
}

tr::has(td[colspan="6"]) {
    display: none !important;
}

.btnAsLink {
    border: none;
    background: none;
}

.btnAsLink:hover {
    cursor: pointer;
}

.btnAsLink:disabled {
    color: rgba(1,1,1,0.5);
}

.btnAsLink:disabled:hover {
    color: rgba(0, 0, 0, 0.5);
    cursor: auto !important;
}

.phoneButton {
    border: 0;
    padding: 0;
    cursor: pointer;
    font-family: inherit; 
    font-size: 100%; 
    line-height: 1.15; 
    margin: 0; 
    background: inherit;
    text-decoration: underline;
    color: blue;
}


#table-head-content > span {
    color: black;
    font-size: larger;
    font-weight: bold;
}

#admin-action-btn > button{
    text-decoration: underline;
    color: blue;
}

#admin-action-btn > button:focus{
    outline: unset;
    box-shadow: unset;
}


#admin-container{
    max-width: 100%;
}

#header{
    color: white;
}

.admin-search-btn{
    height: 54px;
    text-transform: none !important;
    margin-left: 3px !important;
    background-color: gainsboro !important;
    color: royalblue !important;
    -webkit-align-self: center;
            align-self: center;
    white-space: nowrap;
}

.admin-search-btn:focus{
    outline-color: white !important;
}

#date > div > label{
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
}

#admin-content{
    background: white;
    border-radius: 5px;
}

#date-picker > div > label{
    left: -7px;
}

