
#table-head-content > span {
    color: black;
    font-size: larger;
    font-weight: bold;
}

#admin-action-btn > button{
    text-decoration: underline;
    color: blue;
}

#admin-action-btn > button:focus{
    outline: unset;
    box-shadow: unset;
}
